import { Injectable } from '@angular/core';

import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { Presentation, PresentationList } from './presentation';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ApiSearchLegacy } from './api-utils.service';
import { ApiListResponse, ApiResponse } from '../api';
import { Schedule } from './schedule';

@Injectable({
  providedIn: 'root'
})
export class PresentationApiService extends ApiService<Presentation> {

  static readonly PRESENTATION_WRITABLE_FIELDS = [
    'name', 'layout', 'distribution', 'isTemplate', 'embeddedIds', 'productCode',
    'presentationType', 'templateAttributeData', 'credentialsUpdate'
  ];

  static readonly PRESENTATION_SEARCH_FIELDS = [
    'name', 'id', 'revisionStatusName'
  ];

  constructor(
    protected httpClient: HttpClient,
    private userStateService: UserStateService,
    protected companyStateService: CompanyStateService
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/presentations';
  }

  override toServerModel(entity: Presentation): any {
    const fields = pick(entity, PresentationApiService.PRESENTATION_WRITABLE_FIELDS);

    if (this.userStateService.hasRole('ba')) {
      fields.isStoreProduct = entity.isTemplate && entity.isStoreProduct;
    }

    return fields;
  }

  _createSearchQuery (fields, search) {
    var query = '';

    for (var i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }

    query = query.substring(3);

    return query.trim();
  }

  list (search: ApiSearchLegacy, cursor?: string): Promise<ApiListResponse<Presentation>> {
    var query = search.query ?
      this._createSearchQuery(PresentationApiService.PRESENTATION_SEARCH_FIELDS, search.query) : '';
    query += search.filter ? ((search.query ? ' AND' : '') + search.filter) : '';

    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('search', query)
      .set('cursor', cursor)
      .set('count', search.count)
      .set('sort', search.sortBy + (search.reverse ? ' desc' : ' asc'));

    return firstValueFrom(super.listAsObservable(params));
  }

  get (presentationId: string): Promise<ApiResponse<Presentation>> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    return firstValueFrom(super.requestAsObservable(presentationId, 'GET', '', params));
  }

  add (presentation: any): Promise<ApiResponse<Presentation>> {
    return firstValueFrom(super.addAsObservable(presentation));
  }

  update (presentationId: string, presentation: any): Promise<ApiResponse<Presentation>> {
    return firstValueFrom(super.updateAsObservable(presentationId, presentation));
  }

  delete (presentationId: string, forceDelete?: boolean): Promise<ApiResponse<Presentation>> {
    let params = new HttpParams()

    if (forceDelete) {
      params = params.set('force', true);
    }

    return firstValueFrom(super.requestAsObservable(presentationId, 'DELETE', '', params));
  }

  copy (presentationId: string, companyId: string): Promise<ApiResponse<Presentation>> {
    const params = new HttpParams()
      .set('companyId', companyId);

    return firstValueFrom(super.requestAsObservable(presentationId, 'PATCH', 'copy', params));
  }

  publish (presentationId: string): Promise<ApiResponse<string>> {
    return firstValueFrom(super.requestAsObservable(presentationId, 'PATCH', 'publish'));
  }

  restore (presentationId: string): Promise<ApiResponse<Presentation>> {
    return firstValueFrom(super.requestAsObservable(presentationId, 'PATCH', 'restore'));
  }

  export (): Promise<ApiResponse<string>> {
    const companyId = this.companyStateService.getSelectedCompanyId();
    const params = new HttpParams()
      .set('companyId', companyId);

    return firstValueFrom(super.requestAsObservable('', 'GET', 'export', params));
  }

  listEmbeddingPresentations (presentationId: string): Promise<ApiResponse<PresentationList>> {
    return firstValueFrom(super.requestAsObservable(presentationId, 'GET', 'list-embedding-presentations'));
  }

  listEmbeddingSchedules (presentationId: string): Promise<ApiResponse<Schedule>> {
    return firstValueFrom(super.requestAsObservable(presentationId, 'GET', 'list-embedding-schedules'));
  }

}

angular.module('risevision.apps.services')
  .factory('presentation', downgradeInjectable(PresentationApiService));